import Cookies from "universal-cookie"
import { Outlet, Link, useNavigate } from "react-router-dom";

function Logout() {
    const navigate = useNavigate()
    const cookies = new Cookies()
    cookies.set('username', undefined)
    cookies.set('nombre', undefined)
    cookies.set('apellido', undefined)
    navigate('/')

    return(
        <div>Logout</div>
    )
    
}

export default Logout