import CuadradoHoras from "./cuadradoDelCalendario";
import CuadradoSemana from "./CuadradoSemana";
import React, { useEffect, useState } from "react";
import GetAllConsultas from "../api/consulta";
import getDatesOfDayOfWeek from "../getDatesOfDayOfWeek";
import isWrapped from "../dayIsWrapped";
import Cuadrado from "./Cuadrado";
import Header from "./header";
import Footer from "./footer";
import currentMonth from "../api/currentMonth";
import Cookies from "universal-cookie";
import { Outlet, Link } from "react-router-dom";
import AgendaAdministrador from "./AgendaAdministrador";



function Agenda() {

    function mesAnterior() {
        if (month == 0) {
            setMonth(11)
            setYear(year - 1)
        } else {
            setMonth(month - 1)
        }

        
        
    }
    
    function mesSiguiente() {
        if (month == 11) {
            setMonth(0)
            setYear(year + 1)
        } else {
            setMonth(month + 1)
        }
    }

    const cookies = new Cookies()
    const tipo = cookies.get("tipo")
    const username = cookies.get("username")
    const password = cookies.get("password")
    useEffect(() => {
        GetAllConsultas(username, password).then(res => res.json()).then(res => {
            if (res.auth == true) {
                setConsultas(res.consultas)
            }
            
        })
        // currentMonth().then(res => res.text()).then(res => {
        //     setYear(parseInt(res.split("-")[0]))
        //     setMonth(parseInt(res.split("-")[1]))
        // })
    }, [])
    
    var  d = new  Date();

    const[consultas, setConsultas] = useState(null)
    const[month, setMonth] = useState(d.getMonth())
    const[year, setYear] = useState(d.getFullYear())

    let mes

    if (month == 0) {
        mes = "Enero"
    } else if (month == 1) {
        mes = "Febrero"
    } else if (month == 2) {
        mes = "Marzo"
    } else if (month == 3) {
        mes = "Abril"
    } else if (month == 4) {
        mes = "Mayo"
    } else if (month == 5) {
        mes = "Junio"
    } else if (month == 6) {
        mes = "Julio"
    } else if (month == 7) {
        mes = "Agosto"
    } else if (month == 8) {
        mes = "Septiembre"
    } else if (month == 9) {
        mes = "Octubre"
    } else if (month == 10) {
        mes = "Noviembre"
    } else if (month == 11) {
        mes = "Diciembre"
    }
    
    // let year = 2023
    // let month = 8


    if (tipo == "c") {
        return (
            <>
                <Header></Header>
                <div>
                <h1 style={{backgroundColor: "#f8f9fa", textAlign: "center"}}>        
                    Agenda
                </h1>
                <h2 style={{backgroundColor: "#f8f9fa", textAlign: "center"}}>        
                    {mes} {year}
                </h2>
                <hr />

                <button onClick={mesAnterior} class="mesNavigation">Mes Anterior</button>
                <button onClick={mesSiguiente} class="mesNavigation">Mes Siguiente</button>

                <hr />

                <main>
                    <CuadradoSemana dia=""></CuadradoSemana>
                    <CuadradoSemana dia="Lunes"></CuadradoSemana>
                    <CuadradoSemana dia="Martes"></CuadradoSemana>
                    <CuadradoSemana dia="Miércoles"></CuadradoSemana>
                    <CuadradoSemana dia="Jueves"></CuadradoSemana>
                    
                    <CuadradoHoras></CuadradoHoras>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 1) ? getDatesOfDayOfWeek(year, month, 1)[-1] : getDatesOfDayOfWeek(year, month, 1)[0]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 2) ? getDatesOfDayOfWeek(year, month, 2)[-1] : getDatesOfDayOfWeek(year, month, 2)[0]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 3) ? getDatesOfDayOfWeek(year, month, 3)[-1] : getDatesOfDayOfWeek(year, month, 3)[0]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 4) ? getDatesOfDayOfWeek(year, month, 4)[-1] : getDatesOfDayOfWeek(year, month, 4)[0]}></Cuadrado>
    
                    <CuadradoHoras></CuadradoHoras>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 1) ? getDatesOfDayOfWeek(year, month, 1)[0] : getDatesOfDayOfWeek(year, month, 1)[1]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 2) ? getDatesOfDayOfWeek(year, month, 2)[0] : getDatesOfDayOfWeek(year, month, 2)[1]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 3) ? getDatesOfDayOfWeek(year, month, 3)[0] : getDatesOfDayOfWeek(year, month, 3)[1]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 4) ? getDatesOfDayOfWeek(year, month, 4)[0] : getDatesOfDayOfWeek(year, month, 4)[1]}></Cuadrado>
    
                    <CuadradoHoras></CuadradoHoras>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 1) ? getDatesOfDayOfWeek(year, month, 1)[1] : getDatesOfDayOfWeek(year, month, 1)[2]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 2) ? getDatesOfDayOfWeek(year, month, 2)[1] : getDatesOfDayOfWeek(year, month, 2)[2]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 3) ? getDatesOfDayOfWeek(year, month, 3)[1] : getDatesOfDayOfWeek(year, month, 3)[2]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 4) ? getDatesOfDayOfWeek(year, month, 4)[1] : getDatesOfDayOfWeek(year, month, 4)[2]}></Cuadrado>
    
                    <CuadradoHoras></CuadradoHoras>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 1) ? getDatesOfDayOfWeek(year, month, 1)[2] : getDatesOfDayOfWeek(year, month, 1)[3]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 2) ? getDatesOfDayOfWeek(year, month, 2)[2] : getDatesOfDayOfWeek(year, month, 2)[3]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 3) ? getDatesOfDayOfWeek(year, month, 3)[2] : getDatesOfDayOfWeek(year, month, 3)[3]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 4) ? getDatesOfDayOfWeek(year, month, 4)[2] : getDatesOfDayOfWeek(year, month, 4)[3]}></Cuadrado>
    
                    <CuadradoHoras></CuadradoHoras>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 1) ? getDatesOfDayOfWeek(year, month, 1)[3] : getDatesOfDayOfWeek(year, month, 1)[4]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 2) ? getDatesOfDayOfWeek(year, month, 2)[3] : getDatesOfDayOfWeek(year, month, 2)[4]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 3) ? getDatesOfDayOfWeek(year, month, 3)[3] : getDatesOfDayOfWeek(year, month, 3)[4]}></Cuadrado>
                    <Cuadrado cliente year={year} month={month} consultas={consultas} fecha={isWrapped(year, month, 4) ? getDatesOfDayOfWeek(year, month, 4)[3] : getDatesOfDayOfWeek(year, month, 4)[4]}></Cuadrado>
                </main>
                
                
                
    
                </div>
                <Footer></Footer>
            </>
            
          
        );
    } else {
        return (
            // <div>
            //     <Header></Header>
            //     <Link to="/agendaAdmin"><button className='volverIndex'>Administrador</button></Link>
            //     <Footer></Footer>
            // </div>
            <AgendaAdministrador></AgendaAdministrador>
        )
    }

    
}
  
export default Agenda;
