import React, { Component } from 'react'
import Cookies from 'universal-cookie';

const cookies = new Cookies()

function Menu(){
    console.log(cookies.get('username'))
    console.log(cookies.get('nombre'))
    console.log(cookies.get('apellido'))
    console.log(cookies.get('tipo'))
    console.log(cookies.get('password'))

    return (
        <div>
            Menu Principal
        </div>
    )
}

export default Menu;