import React, {useState} from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import usuarioActual2 from './usuarioActual2';
import Agendar from '../api/agendar';
import { Outlet, Link, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie'


function AgendarConsulta() {

    const cookies = new Cookies()
    const cliente = cookies.get('username')
    const password = cookies.get("password")

    const navigate = useNavigate()
    const [especie, setEspecie] = useState(null)
    const [tipo, setTipo] = useState(null)

    let { fecha, horario } = useParams();
    let hora;
    if (horario == 1) {
        hora = "11:00 hs"
    } else if (horario == 2) {
        hora = "12:00 hs"
    } else if (horario == 3) {
        hora = "13:00 hs"
    }

    const AgendarSubmit = async () => {
        if (tipo != null && especie != null) {
            const time = await fetch("http://179.27.123.234:3000/now");
            const timeJson = await time.json();

            const timeClient = new Date(fecha + "T00:00:00-0300")
            
            const obtenerDiaTiempoServidor = new Date(timeJson.time).getDate()

            let flagFechaCorrecta = false;

            if (obtenerDiaTiempoServidor >= 1 && obtenerDiaTiempoServidor <= 20) {
                if (new Date(timeJson.time).getMonth() == timeClient.getMonth() &&
                (new Date(timeJson.time).getFullYear() == timeClient.getFullYear())){
                    flagFechaCorrecta = true;
                } else {
                    flagFechaCorrecta = false;
                }
            } else {
                let mesDiciembre
                let anoAnterior
                if (timeClient.getMonth() == 0) {
                    mesDiciembre = 11
                    anoAnterior = timeClient.getFullYear() - 1
                } else {
                    mesDiciembre = timeClient.getMonth() - 1
                    anoAnterior = timeClient.getFullYear()
                }
                if (new Date(timeJson.time).getMonth() == mesDiciembre &&
                (new Date(timeJson.time).getFullYear() == anoAnterior)){
                    flagFechaCorrecta = true;
                } else {
                    flagFechaCorrecta = false;
                }
            }

            if (flagFechaCorrecta == true) {
                Agendar(password, fecha, horario, tipo, especie, cliente)
                    .then(res => res.json())
                    .then(res => {
                        if (res.auth == true) {
                            navigate('/thankyou')
                        } else {
                            navigate('/denied')
                        }
                    })
            } else {
                alert("Solo puedes agendarte 10 dias antes de que empiece el mes siguiente")
            }
            
            // navigate('/thankyou')
        } else {
            alert("Seleccione tipo y especie");
        }
    }

    function onChangeValueEspecie(event) {
        setEspecie(event.target.value);
    }

    function onChangeValueTipo(event) {
        setTipo(event.target.value);
    }

    return (
        <div>
            <Header></Header>
            <h1>Agendar Consulta</h1>
            <h2>Fecha: {fecha}</h2>
            <h2>Horario: {hora}</h2>
            <form>
                <div>Especie</div>

                <div onChange={onChangeValueEspecie}>
                    <label class="block">Gato <input type="radio" name="especie" value="1"/></label>
                    <label class="block">Perro <input type="radio" name="especie" value="2"/></label>
                </div>

                <div>Tipo</div>

                <div onChange={onChangeValueTipo}>
                    <label class="block">Castración <input type="radio" name="tipo" value="1"/></label>
                    <label class="block">Consulta de Prenez <input type="radio" name="tipo" value="2"/></label>
                </div>
            </form>

            <button class="agendar" onClick={AgendarSubmit}>Agendar Consulta Ahora</button>

            <Footer></Footer>
        </div>
    )
}

export default AgendarConsulta;