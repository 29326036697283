import Header from './components/header';
import Footer from './components/footer';
import { Outlet, Link } from "react-router-dom";

function Registered() {
    return (
        <div>
            <Header></Header>
            <h2>Te has registrado correctamente</h2>
            <Link to="/"><button className='volverIndex'>Volver</button></Link>
            <Footer></Footer>
        </div>
        
    )
}

export default Registered