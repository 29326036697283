import { Routes, Route, useParams } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import ConsultaGetOne from '../api/consultaGetOne';
import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import CancelarConsulta from '../api/cancelarConsulta';

function Consulta() {
    let { fecha, horario } = useParams();
    const [consulta, setConsulta] = useState(null);
    const [auth, setAuth] = useState(false)
    const navigate = useNavigate()
    const cookies = new Cookies()
    const username = cookies.get("username")
    const password = cookies.get("password")
    
    useEffect(() => {
        ConsultaGetOne(username, password, fecha, horario).then(res => res.json()).then(res => {
            if (res.auth == true) {
                setConsulta(res.consultas)
                setAuth(true)
            } else {
                setAuth(false)
            }
            
        })
    }, [])

    const volver = () => {
        navigate("/")
    }

    function cancelar() {
        CancelarConsulta(username, password, fecha, horario).then(res => res.json()).then(res => {
            if (res.auth == true) {
                navigate("/cancelar")
            } else {
                alert("No tiene permiso para eliminar esta consulta")
            }
        })
    }

    let tipoYEspecie

    if (consulta?.lista[0].tipo == 1 && consulta?.lista[0].especie == 1) {
        tipoYEspecie = "Castración de gato"
    } else if (consulta?.lista[0].tipo == 1 && consulta?.lista[0].especie == 2) {
        tipoYEspecie = "Castración de perro"
    } else if (consulta?.lista[0].tipo == 2 && consulta?.lista[0].especie == 1) {
        tipoYEspecie = "Consulta de prenez de gato"
    } else if (consulta?.lista[0].tipo == 2 && consulta?.lista[0].especie == 2) {
        tipoYEspecie = "Consulta de prenez de perro"
    }

    let hora
    
    if (horario == 1) {
        hora = "11:00 hs"
    } else if (horario == 2) {
        hora = "12:00 hs"
    } else if (horario == 3) {
        hora = "13:00 hs"
    }
 
    let date = new Date(fecha)
    date.setDate(date.getDate() + 1)
    let dayOfTheWeek = date.getDay()

    let dayWord
    if (dayOfTheWeek === 0) {
        dayWord = "Domingo"
    } else if (dayOfTheWeek === 1) {
        dayWord = "Lunes"
    } else if (dayOfTheWeek === 2) {
        dayWord = "Martes"
    } else if (dayOfTheWeek === 3) {
        dayWord = "Miércoles"
    } else if (dayOfTheWeek === 4) {
        dayWord = "Jueves"
    } else if (dayOfTheWeek === 5) {
        dayWord = "Viernes"
    } else if (dayOfTheWeek === 6) {
        dayWord = "Sábado"
    }
    
    if (auth == true) {
        return(
            <>
                <Header></Header>
                <div class="consulta">
                    <h1>Consulta</h1>
                    <h2>{consulta?.lista[0].nombre + " " + consulta?.lista[0].apellido}</h2>
                    <h2>{consulta?.lista[0].tel}</h2>
                    <h3>Fecha: {dayWord + " " + fecha}</h3>
                    <h3>Hora: {hora}</h3>
                    <h3>{tipoYEspecie}</h3>
                    <button onClick={cancelar} class="cancelar">Cancelar Consulta</button>
                    <button onClick={volver} class="volver">Volver</button>
                </div>
                <Footer></Footer>
            </>
            
        )
    } else {
        return (
            <>
                <Header></Header>
                <h2>No tienes permiso para ver esta consulta</h2>
                <Link to="/"><button className='volverIndex'>Volver</button></Link>
                <Footer></Footer>
            </>
        )
    }
    
}

export default Consulta;