import React from "react";

function CuadradoSemana({dia}) {
    return (
                <div className="semana fechaSize">
                    {dia}
                </div>
      
    );
}
  
export default CuadradoSemana;
