import Dropdown from 'react-bootstrap/Dropdown';
import usuarioSinFoto from "../images/usuario.png";
import nombreCompleto from "./usuarioActual";
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from 'react-router-dom';

function DropdownExample() {
  const cookies = new Cookies()
  const navigate = useNavigate()

  const logout = () => {
    cookies.remove('username')
    cookies.remove('nombre')
    cookies.remove('apellido')
    cookies.remove('tipo')
    cookies.remove('password')
    navigate("/")
  }

  if (cookies.get('username') === undefined) {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="" id="dropdown-basic">
          <img src={usuarioSinFoto} alt="Usuario" height={'75px'} />
        </Dropdown.Toggle>
  
        <Dropdown.Menu>
          {/* <Dropdown.Item>{nombreCompleto}</Dropdown.Item>
          <Dropdown.Item onClick={Logout} href="/logout">Logout</Dropdown.Item> */}
          <Dropdown.Item href="/#/login">Login</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="" id="dropdown-basic">
          <img src={usuarioSinFoto} alt="Usuario" height={'75px'} />
        </Dropdown.Toggle>
  
        <Dropdown.Menu>
          {/* <Dropdown.Item>{nombreCompleto}</Dropdown.Item>
          <Dropdown.Item onClick={Logout} href="/logout">Logout</Dropdown.Item> */}
          <Dropdown.Item>{cookies.get('nombre') + " " + cookies.get('apellido')}</Dropdown.Item>
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  
}

export default DropdownExample;