function getDatesOfDayOfWeek (year, month, dayOfWeek) {
      const initialDate = new Date(year, month, 1);
      const datesOfDayOfWeek = [];

      while (initialDate.getDay() !== dayOfWeek) {
        initialDate.setDate(initialDate.getDate() + 1);
      }

      while (initialDate.getMonth() === month) {
        const nextDate = new Date(initialDate.getTime());
        datesOfDayOfWeek.push(nextDate.getDate());
        initialDate.setDate(initialDate.getDate() + 7);
      }

      return datesOfDayOfWeek;
}

export default getDatesOfDayOfWeek;