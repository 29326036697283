/*  
let fecha = req.body.lista[0].fecha;
let horario = req.body.lista[0].horario;
let tipo = req.body.lista[0].tipo;
let especie = req.body.lista[0].especie;
let cliente = req.body.lista[0].cliente;
*/
import axios from "axios";

async function Agendar(password, fecha, horario, tipo, especie, cliente) {

    return await fetch('http://179.27.123.234:3000/api/consulta/post', {
        method: "POST",
        body: JSON.stringify({usuario: cliente, contraseña: password, fecha: fecha, horario: horario, tipo: tipo, especie: especie}),
        headers: {"Content-Type": "application/json"}
    })
}

export default Agendar;