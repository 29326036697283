/*

    let nombre = req.body.nombre;
    let apellido = req.body.apellido;
    let username = req.body.username;
    let ci = req.body.ci;
    let tel = req.body.tel;
    let mail = req.body.mail;
    let tipo = req.body.tipo;
    let password = req.body.password;

*/

async function RegisterCliente(nombre, apellido, username, ci, tel, mail, password) {
    return await fetch('http://179.27.123.234:3000/api/cliente/post', {
        method: "POST",
        body: JSON.stringify({nombre: nombre, apellido: apellido, username: username, ci: ci, tel: tel, mail: mail, tipo: 'c', password: password}),
        headers: {"Content-Type": "application/json"}
    })
}

export default RegisterCliente;