function Fecha({fecha}) {
    return (
            <div className="fecha fechaSize">
                {fecha}
            </div>
      
    );
}
  
export default Fecha;
