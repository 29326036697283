import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import AgendaAdministrador from './components/AgendaAdministrador';
import Login from './login';
import Register from './register';
import Principal from './components/principal';
import React, {useState} from 'react';

import Consulta from './components/Consulta';
import Agenda from './components/Agenda';
import { BrowserRouter, Routes, Route, useParams, HashRouter } from 'react-router-dom';
import AgendarConsulta from './components/AgendarConsulta';
import Thankyou from './components/thankyou';
import Menu from './components/Menu';
import Registered from './registered';
import Logout from './logout';
import Denied from './components/denied';
import ConsultaCancelada from './components/ConsultaCancelada';

function App() {

  

  return (
    <div className='body'>
      <HashRouter>
        <Routes>
          <Route path="denied" element={<Denied />} />
          <Route path="registered" element={<Registered />} />
          <Route path="logout" element={<Logout />} />
          <Route index element={<Principal />} />
          <Route path="agendarConsulta/:fecha/:horario" element={<AgendarConsulta />} />
          <Route path="thankyou" element={<Thankyou />} />
          <Route path="agendaAdmin" element={<AgendaAdministrador/>}></Route>
          <Route path="agenda" element={<Agenda/>}></Route>
          <Route path="consulta/:fecha/:horario" element={<Consulta/>}></Route>
          <Route path="login" element={<Login></Login>}></Route>
          <Route path="register" element={<Register></Register>}></Route>
          <Route path="cancelar" element={<ConsultaCancelada></ConsultaCancelada>}></Route>
        </Routes>
      </HashRouter>
      {/* */}
      {/* */}
    </div>
  );
}

export default App;
