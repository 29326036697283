import companyLogo from "../images/ladradoc.png";
import Table from 'react-bootstrap/Table';
import Header from './header';
import Footer from './footer';

function Principal() {
  return (
    <>
      <Header active="Principal"></Header>
      <div>
        <div className='div-foto'>
            <img className="foto-padding" src={companyLogo} alt="" width="358px" height="446px"/>
        </div>
        <h1>Bienvenido a Veterinaria Ladradoc</h1>
        <h2>Precios</h2>
        
        <h3>Castraciones</h3>

        <h4>Requisitos:</h4>
        <h5>8 horas de ayuno de agua y comida</h5>
        <h5>El pago es en efectivo</h5>

        <div>
        <Table striped bordered hover className="div-precios">
          <tbody>
            <tr>
            
            <th></th>

            <th>Socios y no socios</th>

            </tr>

            <tr>

            <th>Caninos</th>

            <td>$ 950</td>

            </tr>

            <tr>

            <th>Felinos</th>

            <td>$ 750</td>

            </tr>
          </tbody>
        </Table>
        </div>

        

        <h3>Hembras en estado de prenez</h3>
        <div>
        <Table striped bordered hover className="div-precios">
          <tbody>
            <tr>
              
              <th></th>

              <th>Socio</th>

              <th>No Socio</th>

              </tr>

              <tr>

              <th>Caninos</th>

              <td>$ 3500</td>

              <td>$ 4000</td>

              </tr>

              <tr>

              <th>Felinos</th>

              <td>$ 2000</td>

              <td>$ 2500</td>

            </tr>
          </tbody>
        
        </Table>
        </div>

        
        
        
      </div>
      <Footer></Footer>
    </>
    
  );
}

export default Principal;
