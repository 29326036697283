function CuadradoHoras() {
    return (
            <div className="horario horaSize">
                <div className="hora-vacio fechaSize">
                    
                </div>
                <div className="hora squareSize">
                    11:00
                </div>
                <div className="hora squareSize">
                    12:00
                </div>
                <div className="hora squareSize">
                    13:00
                </div>
            </div>
    );
}
  
export default CuadradoHoras;
