
import Navigator from "./nav";

function Header({active}) {
    return (
      <div>        
        <Navigator active={active}></Navigator>
      </div>
    );
}
  
export default Header;
