import React, { useState } from 'react';
import {
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
import Header from './components/header';
import Footer from './components/footer';
import { Outlet, Link, useNavigate } from "react-router-dom";
import RegisterCliente from './api/registerCliente';

function Register() {

  const navigate = useNavigate()

  // Estado Componente Register

  const[usernameRegister, setUsernameRegister] = useState("");
  const[password, setPassword] = useState("");
  const[passwordRepeat, setPasswordRepeat] = useState("");
  const[nombre, setNombre] = useState("");
  const[apellido, setApellido] = useState("");
  const[ci, setCi] = useState("");
  const[email, setEmail] = useState("");
  const[tel, setTel] = useState("");

  function handleUsername(e) {
    setUsernameRegister(e.target.value)
  }

  function handlePassword(e) {
    setPassword(e.target.value)
  }

  function handlePasswordRepeat(e) {
    setPasswordRepeat(e.target.value)
  }

  function handleNombre(e) {
    setNombre(e.target.value)
  }

  function handleApellido(e) {
    setApellido(e.target.value)
  }

  function handleCI(e) {
    setCi(e.target.value)
  }

  function handleEmail(e) {
    setEmail(e.target.value)
  }

  function handleTel(e) {
    setTel(e.target.value)
  }

  function handleSignUp() {
    if (password === passwordRepeat) {
      if (nombre != "" && apellido != "" && usernameRegister != "" && ci != "" && tel != "" && email != "" && password != "") {
        RegisterCliente(nombre, apellido, usernameRegister, ci, tel, email, password);
        navigate('/registered')
      } else {
        alert("Debe llenar todos los campos")
      }
      
    } else {
      alert("Las contrasenas no coinciden.")
    }
  }

//   const [justifyActive, setJustifyActive] = useState('tab1');;

//   const handleJustifyClick = (value) => {
//     if (value === justifyActive) {
//       return;
//     }

//     setJustifyActive(value);
//   };

  return (
    <>
      <Header></Header>
      <MDBContainer className="p-3 my-5 d-flex flex-column w-50">



            <div className="text-center mb-3">
              <p>Regístrese en Veterinaria Ladradoc</p>

              <div className='d-flex justify-content-between mx-auto' style={{width: '40%'}}>
                <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
                  <MDBIcon fab icon='facebook-f' size="sm"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
                  <MDBIcon fab icon='twitter' size="sm"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
                  <MDBIcon fab icon='google' size="sm"/>
                </MDBBtn>

                <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
                  <MDBIcon fab icon='github' size="sm"/>
                </MDBBtn>
              </div>

            </div>

            
            <MDBInput onChange={handleUsername} value={usernameRegister} wrapperClass='mb-4' label='Nombre de usuario' id='username' type='text'/>
            <MDBInput onChange={handlePassword} value={password} wrapperClass='mb-4' label='Password' id='password' type='password'/>
            <MDBInput onChange={handlePasswordRepeat} value={passwordRepeat} wrapperClass='mb-4' label='Repetir password' id='passwordRepeat' type='password'/>
            <MDBInput onChange={handleNombre} value={nombre} wrapperClass='mb-4' label='Nombre' id='nombre' type='text'/>
            <MDBInput onChange={handleApellido} value={apellido} wrapperClass='mb-4' label='Apellido' id='apellido' type='text'/>
            <MDBInput onChange={handleCI} value={ci} wrapperClass='mb-4' label='CI' id='ci' type='text'/>
            <MDBInput onChange={handleTel} value={tel} wrapperClass='mb-4' label='Teléfono' id='telefono' type='text'/>
            <MDBInput onChange={handleEmail} value={email} wrapperClass='mb-4' label='Email' id='email' type='email'/>
            

            <div className='d-flex justify-content-center mb-4'>
              <MDBCheckbox name='flexCheck' id='flexCheckDefault' label='I have read and agree to the terms' />
            </div>

            <MDBBtn onClick={handleSignUp} className="mb-4 w-100">Sign up</MDBBtn>

      </MDBContainer>
      <Footer></Footer>
    </>
  );
}

export default Register;