import companyLogo from "../images/Ladradoc.jpg";
import DropdownExample from "./dropdown";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navigator({active}) {
    // return (
    //   <>
    //     <nav className="navbar navbar-expand-lg bg-body-tertiary">
    //     <a href=""><img style={{padding: "10px"}} src={companyLogo} alt="Logo Ladradoc" height={'75px'} /></a>
    //     <div className="container-fluid">
    //         <a className="navbar-brand" href="#">Veterinaria Ladradoc</a>
    //         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //         <span className="navbar-toggler-icon"></span>
    //         </button>
    //         <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //         <ul className="navbar-nav me-auto mb-2 mb-lg-0">
    //             <li className="nav-item">
    //                 <a className="nav-link active" aria-current="page" href="/">Inicio</a>
    //             </li>
    //             <li className="nav-item">
    //                 <a className="nav-link" href="/register">Registrarme</a>
    //             </li>
    //             <li className="nav-item">
    //                 <a className="nav-link" href="/agendaAdmin">Agenda Administrador</a>
    //             </li>
    //             <li className="nav-item">
    //                 <a className="nav-link" href="/agenda">Agenda de Cliente</a>
    //             </li>
                
    //         </ul>
    //         <form className="d-flex" role="search">
    //             <DropdownExample></DropdownExample>
    //         </form>
    //         </div>
    //     </div>
    //     </nav> 
    //   </>
    // );

    return (
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
          <a href=""><img style={{padding: "10px"}} src={companyLogo} alt="Logo Ladradoc" height={'75px'} /></a>
            <Navbar.Brand href="#home">Veterinaria Ladradoc</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <a className="nav-link active" aria-current="page" href="/">Inicio</a>
                <a className="nav-link" href="/#/register">Registrarme</a>
                <a className="nav-link" href="/#/agendaAdmin">Agenda Administrador</a>
                <a className="nav-link" href="/#/agenda">Agenda de Cliente</a>
              </Nav>
              <DropdownExample></DropdownExample>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
}
  
export default Navigator;
