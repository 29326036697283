import Fecha from "./Fecha";
import React, { useEffect, useState } from "react";
import usuario from "./usuarioActual2";
import { Outlet, Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

function Cuadrado({year, month, consultas, fecha, cliente}) {

    

    const cookies = new Cookies()
    let username = cookies.get('username')
    let nombre = cookies.get('nombre')
    let apellido = cookies.get('apellido')

    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const navigate = useNavigate();

    

    function agendar1() {
        // {consultaH1 !== undefined && consultaH1[0] !== undefined ? (consultaH1[0].cliente === usuario ? consultaH1[0].nombre + " " + consultaH1[0].apellido + " - " + tipo1 : "Ocupado") : "Libre"}
        if (consultaH1 !== undefined && consultaH1[0] !== undefined) {
            let fechaFormatted
            let monthFormatted
            let monthPlusOne = month + 1
            if (fecha < 10) {
                fechaFormatted = '0' + fecha;
            } else {
                fechaFormatted = fecha;
            }
            if (month < 9) {
                monthFormatted = '0' + monthPlusOne;
            } else {
                monthFormatted = monthPlusOne;
            }
            let date = year + "-" + monthFormatted + "-" + fechaFormatted
            let horario = 1
            navigate(`/consulta/${date}/${horario}`)
        } else {
            let fechaFormatted
            let monthFormatted
            let monthPlusOne = month + 1
            if (fecha < 10) {
                fechaFormatted = '0' + fecha;
            } else {
                fechaFormatted = fecha;
            }
            if (month < 9) {
                monthFormatted = '0' + monthPlusOne;
            } else {
                monthFormatted = monthPlusOne;
            }
            let date = year + "-" + monthFormatted + "-" + fechaFormatted
            let horario = 1
            navigate(`/agendarConsulta/${date}/${horario}`)
            // console.log(year + "-" + monthFormatted + "-" + fechaFormatted)
            // console.log("1")
        }
    }

    function agendar2() {
        if (consultaH2 !== undefined && consultaH2[0] !== undefined) {
            let fechaFormatted
            let monthFormatted
            let monthPlusOne = month + 1
            if (fecha < 10) {
                fechaFormatted = '0' + fecha;
            } else {
                fechaFormatted = fecha;
            }
            if (month < 9) {
                monthFormatted = '0' + monthPlusOne;
            } else {
                monthFormatted = monthPlusOne;
            }
            let date = year + "-" + monthFormatted + "-" + fechaFormatted
            let horario = 2
            navigate(`/consulta/${date}/${horario}`)
        } else {
            let fechaFormatted
            let monthFormatted
            let monthPlusOne = month + 1
            if (fecha < 10) {
                fechaFormatted = '0' + fecha;
            } else {
                fechaFormatted = fecha;
            }
            if (month < 9) {
                monthFormatted = '0' + monthPlusOne;
            } else {
                monthFormatted = monthPlusOne;
            }
            let date = year + "-" + monthFormatted + "-" + fechaFormatted
            let horario = 2
            navigate(`/agendarConsulta/${date}/${horario}`)
            // console.log(year + "-" + monthFormatted + "-" + fechaFormatted)
            // console.log("2")
            
        }
    }

    function agendar3() {
        if (consultaH3 !== undefined && consultaH3[0] !== undefined) {
            let fechaFormatted
            let monthFormatted
            let monthPlusOne = month + 1
            if (fecha < 10) {
                fechaFormatted = '0' + fecha;
            } else {
                fechaFormatted = fecha;
            }
            if (month < 9) {
                monthFormatted = '0' + monthPlusOne;
            } else {
                monthFormatted = monthPlusOne;
            }
            let date = year + "-" + monthFormatted + "-" + fechaFormatted
            let horario = 3
            navigate(`/consulta/${date}/${horario}`)
        } else {
            let fechaFormatted
            let monthFormatted
            let monthPlusOne = month + 1
            if (fecha < 10) {
                fechaFormatted = '0' + fecha;
            } else {
                fechaFormatted = fecha;
            }
            if (month < 9) {
                monthFormatted = '0' + monthPlusOne;
            } else {
                monthFormatted = monthPlusOne;
            }
            let date = year + "-" + monthFormatted + "-" + fechaFormatted
            let horario = 3
            navigate(`/agendarConsulta/${date}/${horario}`)
            // console.log(year + "-" + monthFormatted + "-" + fechaFormatted)
            // console.log("3")
        }
    }

    let consultaH1;
    let consultaH2;
    let consultaH3;
    let tipo1;
    let tipo2;
    let tipo3;


    if (fecha !== undefined) {
        let fechaCompleta = new Date(year, month, fecha)
        let fechaCompletaISO = fechaCompleta.toISOString().split("T")[0]
        const consulta = consultas?.lista.filter((cons) => cons.fecha.split("T")[0] === fechaCompletaISO)

        if (consulta !== undefined) {
            consultaH1 = consulta.filter((cons) => cons.horario === 1)

            if (consultaH1 !== undefined && consultaH1[0] !== undefined) {
                if (consultaH1[0].tipo === 1) {
                    tipo1 = "Castración"
                } else if (consultaH1[0].tipo === 2){
                    tipo1 = "Prenado"
                }
            }

            consultaH2 = consulta.filter((cons) => cons.horario === 2)

            if (consultaH2 !== undefined && consultaH2[0] !== undefined) {
                if (consultaH2[0].tipo === 1) {
                    tipo2 = "Castración"
                } else if (consultaH2[0].tipo === 2){
                    tipo2 = "Prenado"
                }
            }
    
            consultaH3 = consulta.filter((cons) => cons.horario === 3)

            if (consultaH3 !== undefined && consultaH3[0] !== undefined) {
                if (consultaH3[0].tipo === 1) {
                    tipo3 = "Castración"
                } else if (consultaH3[0].tipo === 2){
                    tipo3 = "Prenado"
                }
            }
        }
        

    }

    let className1
    let className2
    let className3

    
    
    if (cliente) {

        if (consultaH1 !== undefined && consultaH1[0] !== undefined) {
            
            if (consultaH1[0].cliente != "Otro") {
                className1 = 'hora-lg-occupied'
            } else {
                className1 = 'hora-lg-other'
            }
        } else {
            className1 = 'hora-lg'
        }
    
    
        if (consultaH2 !== undefined && consultaH2[0] !== undefined) {
            if (consultaH2[0].cliente != "Otro") {
                className2 = 'hora-lg-occupied'
            } else {
                className2 = 'hora-lg-other'
            }
        } else {
            className2 = 'hora-lg'
        }
    
    
        if (consultaH3 !== undefined && consultaH3[0] !== undefined) {
            if (consultaH3[0].cliente != "Otro") {
                className3 = 'hora-lg-occupied'
            } else {
                className3 = 'hora-lg-other'
            }
        } else {
            className3 = 'hora-lg'
        }
    } else {
        

        if (consultaH1 !== undefined && consultaH1[0] !== undefined) {
            className1 = 'hora-lg-occupied'
        } else {
            className1 = 'hora-lg'
        }

        

        if (consultaH2 !== undefined && consultaH2[0] !== undefined) {
            className2 = 'hora-lg-occupied'
        } else {
            className2 = 'hora-lg'
        }

        

        if (consultaH3 !== undefined && consultaH3[0] !== undefined) {
            className3 = 'hora-lg-occupied'
        } else {
            className3 = 'hora-lg'
        }
    }

    className1 = className1 + " squareSize"
    className2 = className2 + " squareSize"
    className3 = className3 + " squareSize"
    
    if (fecha !== undefined) {

        if (cliente) {
            return (
                <div className="horario squareSize">
                    <Fecha fecha={fecha}></Fecha>
                    <div onClick={agendar1} className={className1}>
                        {/* <h3 style={{textAlign: "right", backgroundColor:"lightblue", border: "1px solid black"}}>1</h3> */}
                        {consultaH1 !== undefined && consultaH1[0] !== undefined ? (consultaH1[0].cliente != "Otro" ? consultaH1[0].nombre + " " + consultaH1[0].apellido + " - " + tipo1 : "Ocupado") : "Libre"}
                    </div>
                    <div onClick={agendar2} className={className2}>
                        
                        {consultaH2 !== undefined && consultaH2[0] !== undefined ? (consultaH2[0].cliente != "Otro" ? consultaH2[0].nombre + " " + consultaH2[0].apellido + " - " + tipo2 : "Ocupado") : "Libre"}
                    </div>
                    <div onClick={agendar3} className={className3}>
    
                        {consultaH3 !== undefined && consultaH3[0] !== undefined ? (consultaH3[0].cliente != "Otro" ? consultaH3[0].nombre + " " + consultaH3[0].apellido + " - " + tipo3 : "Ocupado") : "Libre"}
                    </div>
                </div>
          
            );
        } else {
            return (
                <div className="horario squareSize">
                    <Fecha fecha={fecha}></Fecha>
                    <div onClick={agendar1} className={className1}>
                        {/* <h3 style={{textAlign: "right", backgroundColor:"lightblue", border: "1px solid black"}}>1</h3> */}
                        {consultaH1 !== undefined && consultaH1[0] !== undefined ? consultaH1[0].nombre + " " + consultaH1[0].apellido + " - " + tipo1 : "Libre"}
                    </div>
                    <div onClick={agendar2} className={className2}>
                        
                        {consultaH2 !== undefined && consultaH2[0] !== undefined ? consultaH2[0].nombre + " " + consultaH2[0].apellido + " - " + tipo2 : "Libre"}
                    </div>
                    <div onClick={agendar3} className={className3}>
    
                        {consultaH3 !== undefined && consultaH3[0] !== undefined ? consultaH3[0].nombre + " " + consultaH3[0].apellido + " - " + tipo3 : "Libre"}
                    </div>
                </div>
          
            );
        }
        

        
    } else {
        return (
            <div className="horario squareSize">
                <Fecha fecha={fecha}></Fecha>
                <div className="hora-lg-out squareSize">
                    
                </div>
                <div className="hora-lg-out squareSize">
                    
                </div>
                <div className="hora-lg-out squareSize">

                </div>
            </div>
        )
    }

    
}
  
export default Cuadrado;
