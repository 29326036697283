import axios from "axios";
import md5 from "md5";

async function LoginCliente(usuario, contrasena) {
    let hash = md5(contrasena)
    return await axios.post('http://179.27.123.234:3000/api/login', {
        usuario: usuario, contraseña: hash
    })
}

export default LoginCliente;