import Header from './header';
import Footer from './footer';
import { Outlet, Link } from "react-router-dom";

function ConsultaCancelada() {
    return (
        <>
            <Header></Header>
            <h1>Consulta Cancelada</h1>
            <Link to="/"><button className='volverIndex'>Volver</button></Link>
            <Footer></Footer>
        </>
    )
}

export default ConsultaCancelada;