import React, {useState} from 'react';
import companyLogo from "./images/ladradoc.png";
import {
  MDBContainer,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBIcon
}
from 'mdb-react-ui-kit';
import LoginCliente from './api/loginCliente';
import Cookies from 'universal-cookie';
import { Outlet, Link, useNavigate } from "react-router-dom";
import md5 from 'md5';

const cookies = new Cookies()

function Login() {

  const [usuario, setUsuario] = useState("");
  const [contrasena, setContrasena] = useState("");
  const navigate = useNavigate()

  function handleChangeUsuario(e) {
    setUsuario(e.target.value)
  }

  function handleChangeContrasena(e) {
    setContrasena(e.target.value)
  }

  function handleSignIn() {
    LoginCliente(usuario, contrasena).then(response => {
      console.log(response.data)
      if (response.data.length > 0) {
        console.log("INICIO DE SESION CORRECTO")
        var respuesta = response.data[0]
        cookies.set('username', respuesta.NombreUsuario, {path: "/", maxAge: 3600000})
        cookies.set('nombre', respuesta.Nombre, {path: "/", maxAge: 3600000})
        cookies.set('apellido', respuesta.Apellido, {path: "/", maxAge: 3600000})
        cookies.set('tipo', respuesta.Tipo, {path: "/", maxAge: 3600000})
        cookies.set('password', respuesta.password, {path: "/", maxAge: 3600000})
        const tipo = cookies.get('tipo')
        if (tipo == "a") {
          navigate('/agendaAdmin')
        } else {
          navigate('/agenda')
        }
        
      } else {
        console.log("ERROR")
        alert("Usuario y/o contrasena incorrectos")
      }
    })
  }

  return (
    <MDBContainer style = {{maxWidth: "400px"}} className="p-3 my-5 d-flex flex-column w-50">
        <div className='div-foto'>
            <img className="foto-padding foto-size" src={companyLogo} alt="" />
        </div>
        

      <MDBInput wrapperClass='mb-4' label='Nombre de usuario' id='form1' type='email' value={usuario} onChange={handleChangeUsuario}/>
      <MDBInput wrapperClass='mb-4' label='Contrasena' id='form2' type='password' value={contrasena} onChange={handleChangeContrasena}/>

      <div className="d-flex justify-content-between mx-3 mb-4">
        <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
        <a href="!#">Forgot password?</a>
      </div>

      <MDBBtn onClick={handleSignIn} className="mb-4">Sign in</MDBBtn>

      <div className="text-center">
        <p>Not a member? <a href="#!">Register</a></p>
        <p>or sign up with:</p>

        <div className='d-flex justify-content-between mx-auto' style={{width: '40%'}}>
          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
            <MDBIcon fab icon='facebook-f' size="sm"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
            <MDBIcon fab icon='twitter' size="sm"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
            <MDBIcon fab icon='google' size="sm"/>
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
            <MDBIcon fab icon='github' size="sm"/>
          </MDBBtn>

        </div>
      </div>

    </MDBContainer>
  );
}

export default Login;