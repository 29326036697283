function isWrapped(year, month, dayofweek) {
    const date = new Date(year, month, 1)
    let day = date.getDay()
    console.log(day)
    
    if (dayofweek === 1) {
      if (day === 5 || day === 6 || day === 0 || day === 1) {
        return false
      } else if (day === 2 || day === 3 || day === 4) {
        return true
      }
    }
    
    if (dayofweek === 2) {
      if (day === 5 || day === 6 || day === 0 || day === 1 || day === 2) {
        return false
      } else if (day === 3 || day === 4) {
        return true
      }
    }
    
    if (dayofweek === 3) {
      if (day === 5 || day === 6 || day === 0 || day === 1 || day === 2 || day === 3) {
        return false
      } else if (day === 4) {
        return true
      }
    }
    
    if (dayofweek === 4) {
      return false
    }
    
  }

  export default isWrapped;